<template>
  <div class="forget-password">
    <h1 class="text-light">{{ $t('auth.forget-password') }}</h1>

    <form method="post" v-on:submit.prevent="sendEmail" name="forget-password">
      <div class="form-group with-icon-left">
        <div class="input-group" :class="error ? 'has-error' : ''">
          <input type="email" id="email" v-model="email" required />
          <i class="fa fa-at icon-left input-icon"></i>
          <label class="control-label" for="email">{{
            $t('auth.email')
          }}</label>
          <i class="bar"></i>
          <span class="small text-danger">{{ error }}</span>
        </div>
      </div>
      <div class="d-flex align--center justify--center">
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          v-if="formLoading"
          class="mx-3"
        />
        <button
          v-else
          type="submit"
          class="btn btn-primary btn-sm btn-with-icon-sm btn-block"
        >
          <div class="btn-with-icon-content">
            {{ $t('auth.send') }}
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { api } from '../../../axios'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import { mapGetters } from 'vuex'

export default {
  name: 'forget-password',
  data() {
    return {
      formLoading: false,
      email: '',
      error: '',
      pin_code: ''
    }
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  components: {
    HalfCircleSpinner
  },
  methods: {
    async sendEmail() {
      this.formLoading = true
      try {
        this.error = ''
        const response = await api.post(`auth/user/forget-password`, {
          email: this.email
        })
        this.toast(this.$t('auth.forget.success'))
      } catch (e) {
        this.error = e.response.data.message
        this.toast(this.error, {
          type: 'error'
        })
      }
      this.formLoading = false
    }
  }
}
</script>

<style lang="scss">
.forget-password {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h1 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: 30px;
  }
  width: 21.375rem;

  .down-container {
    margin-top: 3.125rem;
  }
}
</style>
